// custom_geocode.js
var custom_btn_handline = {
    init: function () {

        if ($(".get_lat_long_from_venue").length > 0) {
            custom_btn_handline.update_lat_long();
        }

    },
    update_lat_long: function () {
        $(".get_lat_long_from_venue").on("click", function (ev) {
            ev.preventDefault();
            var venueAddressString = "";
            
            var venueAddressInput1 = document.getElementById("event_venue_address1");
            if (venueAddressInput1 && venueAddressInput1.value) {
                venueAddressString += venueAddressInput1.value;
            }
            var venueAddressInput2 = document.getElementById("event_venue_address2");
            if (venueAddressInput2 && venueAddressInput2.value) {
                venueAddressString += " ";
                venueAddressString += venueAddressInput2.value;
            }
            var venueAddressCityInput = document.getElementById("event_venue_city");
            if (venueAddressCityInput && venueAddressCityInput.value) {
                venueAddressString += ",";
                venueAddressString += venueAddressCityInput.value;
            }
            var venueAddressStateInput = document.getElementById("event_venue_state");
            if (venueAddressStateInput && venueAddressStateInput.value) {
                venueAddressString += ",";
                venueAddressString += venueAddressStateInput.value;
            }
            
            var venueAddressZipInput = document.getElementById("event_venue_zip");
            if (venueAddressZipInput && venueAddressZipInput.value) {
                venueAddressString += ",";
                venueAddressString += venueAddressZipInput.value;
            }
            
            var venueAddressCountryInput = document.getElementById("event_venue_country");
            if (venueAddressCountryInput && venueAddressCountryInput.value) {
                venueAddressString += ",";
                venueAddressString += venueAddressCountryInput.value;
            }
            
            if (venueAddressString) {
            
                var geocoder = new google.maps.Geocoder();

                geocoder.geocode( { 'address': venueAddressString}, function(results, status) {

                    if (status == google.maps.GeocoderStatus.OK) {
                        var latitude = results[0].geometry.location.lat();
                        var longitude = results[0].geometry.location.lng();
                        
                        document.getElementById("event_venue_lat").value = latitude;
                        document.getElementById("event_venue_long").value = longitude;
                    }
                });
            }
            
        });

    }
};

$(document).ready(function () {
    custom_btn_handline.init();
});