// custom_popup.js
var custom_popup = {
    init: function () {

        if ($(".confirm_before_submit").length > 0) {
            custom_popup.delete_comfirmation();
        }

    },
    delete_comfirmation: function () {
        $(".confirm_before_submit").on("click", function (ev) {
            ev.preventDefault();
            var customMsg = $(this).attr("data-message");
            var actionLink = $(this).attr("href");
            var actionMethod = $(this).attr("data-method");

            bootbox.confirm(customMsg, function (result) {
                if (result == true) {
                    $('#submitterForm').attr('action', actionLink);
                    $('input[name=_method]').val(actionMethod);
                    $('#submitterForm').submit();
                } else {
                    // do nothing
                }
            }).find('.modal-content').css({'margin-top': '50%'});
            ;
        });

    }
};

$(document).ready(function () {
    custom_popup.init();
});